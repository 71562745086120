<template>
  <div id="c-body">
    <CBanner :bannerCont="bannerCont"/>
    <div id="content">
      <p style="
      text-align: center;    
      font-size: 22px;    
      line-height: 50px;"
      >
        公司简介
      </p>
      <p>西安核创源科技有限公司创立于二〇一八年一月，公司以服务国家重大科技需求为使命，以促进产学研结合和科技成果转化为宗旨，以核能及相关领域的应用软件自主化研发和技术服务为核心业务，为国内外核能及相关领域提供软件研发、技术攻关、技术服务与咨询等。</p>
      <p>公司坚持以需求为导向，面向复杂的实际工程问题提供解决方案，坚持以量身定制技术为核心，做到精心服务全方位掌控；坚持以自主攻关为使命，为攻克卡脖子难题做出自己的贡献。</p>
      <p>公司已自主研发了压水堆燃料管理计算软件Bamboo系列、先进快谱反应堆分析系统软件SARAX系列、概率统计方法输运软件MCX、下一代高保真一步法计算软件X系列、高性能大规模输运计算软件Hydra系列和多用途核数据库制作系统软件Atlas系列等。同时，公司针对核能领域的不同需求，提供各类技术服务和咨询服务，包括核电厂换料校核计算，调试技术支持，运行技术改造，分析软件研发，数据处理等。</p>
      <p>公司将继承和发扬西安交通大学NECP实验室数十年来的学术积淀和优良传统，秉承“以核为贵、创新发展”的理念，逐步打造核能民营企业的新面貌。</p>      
    </div>
    <div class="footer">
      <Footer/>
    </div>
  </div>
</template>

<script>
import CBanner from '../common/content/CBanner.vue'
import Footer from '../common/Footer'
export default {
  name: "abouts",
  components: {
    'CBanner': CBanner,
    'Footer': Footer
  },
  data() {
    return {
      bannerCont: {
        pic: require('../../../assets/show-banner.png'),
        title: "公司简介",
        txt: "面向复杂的实际工程问题提供解决方案, 做到精心服务全方位掌控, 为攻克卡脖子难题做出自己的贡献坚持以需求为导向，以量身定制技术为核心，以自主攻关为使命",
      },
    }
  }
}
</script>

<style scoped>
div#c-body {
  max-width: 1920px;
  margin: 0 auto;
}
div#content {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 100px;
  color: #323232;
  text-indent: 2em;
  font-size: 14px;
  line-height: 240%;
  text-align: justify;
  text-justify: distribute;
}
div.footer {
  position: relative;
  top: 100px;
}
</style>
